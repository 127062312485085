import { loginText } from "../texts/loginText";
import { types } from "../types/types";

const initialState = {
  texts: {
    login: loginText,
  },
};

export const textsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.textsSetValues:
      return {
        ...state,
        texts: action.payload,
      };
    default:
      return state;
  }
};
