import axios from "axios";
import env from "../../environment/environment";
import { filterEmpty } from "../../helpers/filterEmpty";
import { store } from "../../store/store";

const urlCheckAuth = env.checkAuth;


export const getCheckAuthHttp = async (token) => {
  try {
    const headers = {
      authorization: `Bearer ${token}`,
      "Ocp-Apim-Subscription-Key": "a592492512254ef78eada2516d4a204d"
    }
    const url = `${urlCheckAuth}`;
    const { data: res } = await axios({
      method: "get",
      url,
      headers,
    });

    return res;
  } catch (error) {
    console.info(error);
    throw error;
  }
};
