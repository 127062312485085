import jwt_decode from "jwt-decode";
import { getCheckAuthHttp } from "../services/Login/loginService";
import { store } from "../store/store";

export const useProvideAuth = () => {
  const user = store.getState().login.accessToken;
  return {
    user,
  };
};

export const authCheck = async(token) => {
  try {
    const { authorized } = await getCheckAuthHttp(token);
    return authorized;
  } catch (e) {
    throw e;
  }
};

export const getValuesOfAuth = (token) => {
  if (token) {
    const { name, email, privacyNotice, studentEnrollmentNumber, webApp } = jwt_decode(token);
    const { url } = webApp
    return {
      name,
      email,
      userId: "346901",
      mask: studentEnrollmentNumber,
      phone: '5512345678',
      url,//'https://enzymejs.github.io/enzyme/',
      isTerms: privacyNotice.privacy_notice_agreed && privacyNotice.terms_and_conditions_agreed,
    };
  }
  else {
    return {
      name: null,
      email: null,
      userId: null,
      mask: null,
      isTerms: false
    };
  }
};
