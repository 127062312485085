import React, { useEffect, useState } from "react";
import "lottus-login/login";
import { Button } from "../Button/Button";
import { buttonLogin } from "../../constants/Login.constant";
import { useSelector } from "react-redux";
import env from "../../environment/environment";

export const ViewLogin = React.memo(({ isInputs, isForgot, onForm, onPrivacyTerms,data,btnTitle='' }) => {
  const loginRef = React.createRef();
  const [next] = useState({
    ...buttonLogin
  });

  const {texts} = useSelector(state => state.texts);

  useEffect(() => {
    loginRef.current.isInputs = true;
    loginRef.current.isForgot = true; 
    loginRef.current.data = data || {};
  }, [isInputs,isForgot,data]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    loginRef.current.imgLogin = env.contentAssets + texts?.login?.images?.imgLogin;
    loginRef.current.imgLogo = env.contentAssets + texts?.login?.images?.imgLogo;
    loginRef.current.addEventListener('onPrivacyTerms', onPrivacyTerms);
  },[]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <lottus-login ref={loginRef} >
        <div buttonsubmit="">
          <Button data={next} onClick={onForm} />
        </div>
      </lottus-login>
    </>
  );
});
