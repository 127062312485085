import axios from "axios";
import env from "../../environment/environment";

const baseUrl = env.contentAssets+'texts/';

export const getContentStatic = async (path) => {
  try {
    const url = `${baseUrl}${path}`
    const res = await axios({
      method: "get",
      url,
    });
    return res.data;
  } catch (error) {
    return null;
  }
};
