export const loginText = {
  images: {
    imgLogo: "images/UTC.svg",
    imgLogin: "images/coverpage.png",
    splashImg: "images/welcome.png",
  },
  title: 'Ingresar',
  subtitle: 'Inicie sesión mediante su cuenta organizativa',
  footerText: 'Aviso de privacidad y Términos y condiciones',
  mark: '© Lottus 2022',
  splashText: "Te damos la bienvenida al Campus Virtual",
  errorMsg400: "No pudimos encontrar al usuario indicado",
  errorMsg500: "Ha ocurrido un error, inténtalo más tarde",
  errorMsg403:"Prohibido el acceso debido a sus datos",
  errorMsg503:"Error de servidor. Comunicarse con mesa de ayuda"
};
