import { types } from "../types/types"

const initialState = {
  accessToken: null,
  refreshToken: null
}


export const loginReducer = (state = initialState,action) => {
  switch(action.type) {

    case types.loginSetTokens:
      return {
        ...state,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken
      }
   
    default:
      return state;
  }
}
 