import React from "react";
import { useSelector } from "react-redux";
import "./Loading.scss";

const Loading = () => {

    const loadingStore = useSelector(state => state.loading)

    return (
        <div className="loading-container" style={{visibility:loadingStore.isShow ? 'visible': 'hidden'}}>
            <div className="loadingcontent" >
           <div className="loading"></div>
           </div>
        </div>
    )
}
 
export default Loading;