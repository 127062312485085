import { types } from "../types/types"

const initialState = {
  isShow : false
}


export const loadingReducer = (state = initialState,action) => {
  switch(action.type) {

    case types.loadingShow:
      return {
        ...state,
        isShow: action.payload.isShow

      }
   
    default:
      return state;
  }
}
 