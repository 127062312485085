import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { setLoading } from "../actions/loadingAction";
import { Feedback } from "../components/Feedback/Feedback";
import { ViewLogin } from "../components/ViewLogin/ViewLogin";
import { codeErrosLogin, feedbackErrorLogin } from "../constants/Login.constant";
import env from "../environment/environment";
import { authCheck, getValuesOfAuth } from "../helpers/auth";
import { contentStaticMiddleware } from "../helpers/contentStatic";
import "./Login.scss";

const Login = () => {
  const dispatch = useDispatch();
  const { texts } = useSelector((state) => state.texts);

  const query = new URLSearchParams(useLocation().search);

  const [dataLogin,setDataLogin] = useState({
    title: '',
    subtitle: '',
    footerText: '',
    mark: ''
  })

  const [isErrorLogin, setIsErrorLogin] = useState({ valid: false, msg: null });

  useEffect(() => {
    dispatch(contentStaticMiddleware())
    const status = query.get("status_id");
    if (status === codeErrosLogin.error400) {
      setIsErrorLogin({
        valid: true,
        msg: texts?.login?.errorMsg400,
      });
    } else if (status === codeErrosLogin.error500) {
      setIsErrorLogin({
        valid: true,
        msg: texts?.login?.errorMsg500,
      });
    } else if (status === codeErrosLogin.error403) {
      console.log('entro ss')
      setIsErrorLogin({
        valid: true,
        msg: texts?.login?.errorMsg403,
      });
    } else if (status === codeErrosLogin.error503){
      setIsErrorLogin({
        valid: true,
        msg: texts?.login?.errorMsg503,
      });
    } else {
      const accesstoken = query.get("accessToken");
      const refreshToken = query.get("refreshToken");
      if (accesstoken && refreshToken) {
        dispatch(setLoading(true));
        authCheck(accesstoken)
          .then((check) => {
            const { url } = getValuesOfAuth(accesstoken);
            console.log('URL: ',url);
            if (check) {
              window.location.href = `${url}/login?accessToken=${accesstoken}&refreshToken=${refreshToken}`;
            }
            else {
              dispatch(setLoading(false));
            }
          })
          .catch((e) => {
            dispatch(setLoading(false));
          });
      } 
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setDataLogin({
      title: texts?.login?.title,
      subtitle: texts?.login?.subtitle,
      footerText: texts?.login?.footerText,
      mark: texts?.login?.mark
    })
  },[texts]);

  return (
    <>
      <ViewLogin
        data={dataLogin}
        btnTitle={texts?.login?.btnNext}
        onForm={() => {
          window.location.href = env.redirectLogin;
        }}
        onPrivacyTerms={() => {
          window.location.href = `${env.redirectTerms}`;

        }}
      ></ViewLogin>
      {isErrorLogin.valid ? (
        <div className="feedbacklogin">
          <Feedback
            data={feedbackErrorLogin}
            text={isErrorLogin.msg}
            onRight={() => {
              setIsErrorLogin({ valid: false, msg: "" });
            }}
          />
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Login;
