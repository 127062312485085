import React, { useEffect } from "react";
import "./App.scss";
import { Provider} from "react-redux";
import { store } from "./store/store";
import Login from "./Login/Login";
import Loading from "./components/Loading/Loading";
import { BrowserRouter } from "react-router-dom";
require("dotenv").config();

const App = () => {

  

  return (
    <>
      <Provider store={store}>
        <BrowserRouter>
          <Login />
        </BrowserRouter>
        <Loading />
      </Provider>
    </>
  );
};

export default App;
