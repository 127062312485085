import { setTextValues } from "../actions/textsAction";
import { getContentStatic } from "../services/static/staticService"
import { loginText } from "../texts/loginText";
const contentStatic = async() => {
  try {
    const res = await getContentStatic('loginText.json');
    return {
      login: res || loginText,
    }
  } catch (error) {
    return null;
  }
}

export const contentStaticMiddleware = () => {
  return (dispatch) => {
    contentStatic().then((texts) => {
      dispatch(setTextValues(texts));
    })
  }
}